import React, { useState } from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { map, get, filter, groupBy, isEmpty, keys } from 'lodash/fp'

import { Box, Flex, Text } from 'system'
import { BoundedBox } from 'src/components/BoundedBox'
import { Button } from 'src/components/Button'
import { ButtonArrow } from 'src/components/ButtonArrow'
import { Heading } from 'src/components/Heading'
import { Subheading } from 'src/components/Subheading'
import { TwoColumnContent } from 'src/components/TwoColumnContent'

const FilterButton = ({
  children,
  name,
  isSelected,
  toggleRequirement,
  ...props
}) => (
  <Box as="li" mb={2} boxStyle="lastNoMargin" {...props}>
    <Button
      as="button"
      onClick={() => toggleRequirement(name)}
      bg={isSelected ? 'yellow' : 'sandLight'}
      color={isSelected ? 'navy' : 'teal'}
      minWidth={0}
      width={0.9}
    >
      {children}
    </Button>
  </Box>
)

const render = ({ requirement, setRequirement, ...props }) => queryData => {
  const providers = get(
    'file.childMarkdownRemark.frontmatter.providers',
    queryData
  )

  let filteredItems = providers
  if (!isEmpty(requirement))
    filteredItems = filter(get(['requirements', requirement]), providers)
  const filteredItemsByGroup = groupBy('group', filteredItems)
  const groupNames = keys(filteredItemsByGroup)

  const toggleRequirement = selectedRequirement =>
    selectedRequirement === requirement
      ? setRequirement(null)
      : setRequirement(selectedRequirement)

  return (
    <BoundedBox as="section" maxWidth="large" {...props}>
      <TwoColumnContent
        renderLeft={() => (
          <Box as="ul">
            <FilterButton
              isSelected={requirement === null}
              name={null}
              toggleRequirement={toggleRequirement}
            >
              All
            </FilterButton>
            <FilterButton
              isSelected={requirement === 'preauthorization'}
              name="preauthorization"
              toggleRequirement={toggleRequirement}
            >
              Requires Preauthorization
            </FilterButton>
            <FilterButton
              isSelected={requirement === 'pcp_referral'}
              name="pcp_referral"
              toggleRequirement={toggleRequirement}
            >
              Requires PCP Referral
            </FilterButton>
          </Box>
        )}
        renderRight={() => (
          <>
            <Heading mb={[2, 4]}>Insurance Type</Heading>
            <Flex mb={5} flexDirection={['column', 'row']}>
              {map(
                groupName => (
                  <Box
                    boxStyle="lastNoMargin"
                    flex={[null, '1 1 0px']}
                    mb={[3, 0]}
                    mr={[null, 4]}
                  >
                    <Subheading mb={2}>{groupName}</Subheading>
                    <Box as="ul">
                      {map(
                        item => (
                          <Box as="li" mb={1} boxStyle="lastNoMargin">
                            <Text>{get('title', item)}</Text>
                          </Box>
                        ),
                        get(groupName, filteredItemsByGroup)
                      )}
                    </Box>
                  </Box>
                ),
                groupNames
              )}
            </Flex>
            <ButtonArrow to="/for-patients/" direction="left">
              Back
            </ButtonArrow>
          </>
        )}
      />
    </BoundedBox>
  )
}

export const InsuranceProviders = props => {
  const [requirement, setRequirement] = useState(null)

  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: { eq: "customPages/insurance.md" }) {
            childMarkdownRemark {
              frontmatter {
                providers {
                  group
                  title
                  requirements {
                    preauthorization
                    pcp_referral
                  }
                }
              }
            }
          }
        }
      `}
      render={render({ requirement, setRequirement, ...props })}
    />
  )
}
