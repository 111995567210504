import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { get } from 'lodash/fp'

import { Layout } from 'src/components/Layout'

import { InsuranceProviders } from 'src/slices/InsuranceProviders'
import { PageTitle } from 'src/slices/PageTitle'
import { Summary } from 'src/slices/Summary'

const InsurancePage = ({ data }) => {
  const page = get('file.childMarkdownRemark', data)

  return (
    <Layout>
      <Helmet>
        <title>{get('frontmatter.title', page)}</title>
      </Helmet>
      <PageTitle
        imageFluid={get('frontmatter.image.childImageSharp.fluid', page)}
      >
        {get('frontmatter.title', page)}
      </PageTitle>
      {get('frontmatter.description', page) && (
        <Summary markdown={get('frontmatter.description', page)} />
      )}
      <InsuranceProviders />
    </Layout>
  )
}

export default InsurancePage

export const query = graphql`
  query InsurancePage {
    file(relativePath: { eq: "customPages/insurance.md" }) {
      childMarkdownRemark {
        frontmatter {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  }
`
